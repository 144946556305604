/*
  GENERAL
*/

$navbar-height: 48px;
$accent-color: #e83e8c;
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,400i,700i');

*{
  font-family: sans-serif;
}
p, h1, h2, h3, h4, h5, h6, label {
  font-family: 'Montserrat', serif;
}

b,
strong {
  font-weight: bold;
}

.container {
  padding: 15px;
}

#page{
  min-height: 100vh;
  padding-top: $navbar-height;
}

.accent-color {
  color: $accent-color;
}

.bg-lightgray {
  background-color: #999999;
}

/*
  NAVBAR
 */
.navbar {
  padding: .2rem 1rem;
}

.navbar + * { // Navbars immediate sibling
  margin-top: $navbar-height;
}

.logged_in_as {
  width: 100%;
  font-size: 0.8em;
  text-align: center;
  display: block;
  position: relative;
  top: -10px;
  margin-bottom: 0;
  color: gray;
}

.navbar-dealership {
  text-align: center;
  color: #fff;
  display: inline-block;
  padding-bottom: 5px;
  margin: 0;
  font-size: 1.25rem;
  line-height: 0;
  white-space: nowrap;
}

.navbar-brand {
  margin: 0;

  &:hover {
    color: #9EA2A4 !important;
  }
}


a.navbar-logo {
  &:hover {
    text-decoration: none;
    text-underline: none;
    color: #aaa;
  }

  color: #fff;
}

span.navbar-logo {
  font-size: 28px;
  font-family: 'Montserrat', serif;
  font-style: italic;
}

a.navbar-dealership {
  text-decoration: underline;

  &:hover {
    color: #aaa;
  }

  &:active {
    color: orange;
  }

  color: #fff;
}

.navbar-slogan {
  font-size: 1.2em;
  font-family: 'Montserrat', serif;
  font-style: Normal;
}


//
// MMENU
//
#menu:not( .mm-menu ) {
  display: none;
}

.dv-star-rating-star{
  font-size: 2em;
}
.dv-star-rating > label{
  margin-bottom: 0;
}

//
//
//
.rounded-count-box {
  // text-center mr-2 p-2 border rounded
  text-align: center;
  padding: (0.25rem * 2);

  border: 1px solid rgba(0, 0, 0, .1);
  border-radius: .25em;
}

.dashboard-action-buttons {
  // max-width: 1000px;
  div {
    margin: 10px;

    a.dashboard-button {
      display: table-cell;
      vertical-align: middle;
      color: white !important;
      width: 12rem;
      height: 80px;
    }
  }
}

.dashboard-employee-panel {
  border: 1px solid gray;
  margin-bottom: 2em;
}

.instrument-action-panel > button {
  margin: 0.2em;
}

.netatmo-logo {
  height: 15px;
  width: auto;
  background: transparent;
}

.netatmo-connected-checkmark {
  color: green;
}

.netatmo-logo-link {
  padding: 10px;
  border: 1px solid black;

  &:hover {
    text-decoration: none;
    background-color: lightgray;
    opacity: 0.8;
  }
}

.no-underline {
  text-decoration: none !important;
}

.force-breakable {
  word-break: break-all
}

.prohibit-break {
  white-space: nowrap;
}
.no-break {
  white-space: nowrap;
}

.tile > *{
  white-space: nowrap;
}


body {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  /*
  background: #403B4A; // fallback for old browsers
  background: -webkit-linear-gradient(to right, #E7E9BB, #403B4A); // Chrome 10-25, Safari 5.1-6
  background: linear-gradient(to right, #E7E9BB, #403B4A); // W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+
  */
}

table > tbody > tr > td {
  vertical-align: middle !important; // Vertical align all table cells
}

// Allow breaking in calendar cells
.fc-day-grid-event .fc-content {
  white-space: normal !important;
}

#calendar td {
  vertical-align: top !important;
}

.d-none-important {
  display: none !important;
}

.margin-auto {
  margin: auto !important;
}

tr:hover {
  //background-color: lightgrey !important; // Hover effect
}

.ptr--ptr {
  background-color: white !important;
}

.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//Make datatables occupy 100% width
.dataTables_wrapper {
  & > div:first-child {
    display: none;
  }

  & > div:nth-child(2) {
    width: 100%;

    & > div {
      width: 100%;
    }
  }
}

table.dataTable {
  border-collapse: collapse !important;
}

// Datatable responsive modal should be behind the event-modal
.dtr-bs-modal {
  z-index: 1049 !important;
}

.modal-dialog {
  min-height: calc(100vh - 45px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
  @media(max-width: 768px) {
    min-height: calc(100vh - 20px);
  }
}


#event-modal .modal-dialog {
  //margin: 0;
  max-width: 1200px;
}

#event-modal .modal-body {
  padding: 0;
  margin-left: 15px;
  margin-right: 15px;
}

#event-modal .event {
  padding-bottom: 1em;
  display: inline-block;
  background: lightgoldenrodyellow;
  text-align: left;

  .poc-card.quick {
    color: black !important;
    background: white;
    min-height: 10em;
    //min-width: 13em;
    border: 1px solid black;
    max-width: 20em;
    margin-bottom: 1em;
    margin-right: 1em;

    & a {
      color: blue !important;
    }

    .main {
      padding: 1em;
    }

    .address {
      padding: 1em;
      background: #eaeaea;
    }
  }


  &.appointment {
    background: #92a8d1;
    color: white !important;

    a {
      color: antiquewhite;
    }

    .border {
      border-color: #034f84 !important;
    }

    .text-muted {
      color: #555 !important;
    }

    span.model {
      color: #333 !important;
    }

    label.text-muted, small.text-muted {
      color: white !important;
    }
  }

  .event-details {
    border: 1px solid #ccc;
    border-top: 0;
    display: inline-block;
    padding: 1em;
    background: #eaeaea;
    color: black;

  }

  .event-title-container {
    padding-bottom: 0.4em;
  }

  .event-title {
    color: black !important;
  }

  #event-title {
    max-width: 150px;
  }

  #note {
    line-height: 1;
  }

  .postpone-group {
    max-width: 10em;
  }

}

#event-modal .contacts {
  padding: 0 !important;

  .contact-type-header {
    text-align: left;
    padding-bottom: 0.5em;
  }

  .owners-contacts {
    padding: 1em;
    background: lightsalmon;
    border: 1px solid black;
  }

  .departments-contacts {
    padding: 1em;
    background: lightblue;
    border: 1px solid black;
  }

  .poc-card {
    background: white;
    min-height: 10em;
    min-width: 13em;
    border: 1px solid black;
    max-width: 20em;
    margin-bottom: 1em;
    margin-right: 1em;
  }

  .poc-card .address {
    padding: 1em;
    background: #eaeaea;
  }

  .poc-card.department .main, .poc-card.owner .main {
    padding: 1em;
  }

  .poc-card.department {
    text-align: right;
  }

  .poc-card.owner {
    text-align: left;
  }

  .poc-card.new {
    &:hover {
      cursor: pointer;
    }

    background: #eaeaea;
  }

  .poc-card.poc {
    padding: 0;
  }

  .poc-card.poc .action-buttons {
    padding-left: 0.25em !important;
    padding-right: 0.25em !important;

    a {
      color: gray !important;
    }
  }

  .poc-card.poc .main {
    padding: 0 1em 1em 1em;
  }
}

#service-item-table {
  .type {
    font-weight: bold;
  }
}

/*
  MMENU
*/
.mm-slideout {
  z-index: auto !important;
}

nav#mmenu {
  margin-top: $navbar-height; // Needed to avoid collision with navbar
}

nav#mmenu:not( .mm-menu ) {
  display: none; // Hide mmenu before initialization
}


/*
  FLEX SYSTEM W/ GUTTERS
 */
.container-fluid {
  padding: 15px;
}

.container-flex-margin {
  padding-right: 5px;
  padding-left: 5px;

  & > div > .card {
    margin: 0 7px 15px;
  }
}


/*
  Instrument contact form end
 */
ul#login-as-user-list {
  > li {
    margin: 1em 0;
  }
}

.link-sink {
  position: relative;
  bottom: -10px;
  left: -5px;
}

span.line {
  display: inline-block;
}

.card-header-icon {
  font-size: 2em;
  padding: 0.5rem !important;
}

.card {
  border-radius: 0 !important; /* Remove all rounded corners from cards*/
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.btn {

  //box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
  border-radius: 1pt;
}

.btn:hover {
  //box-shadow: 0 7px 14px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22);
}

.card-subtitle {
  padding-top: 0.3em;
}

.fault {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.calendar-technician-box {
  display: inline-block;
  padding: 1em;
  border: 3px solid;
}

.calendar-scheduling {
  font-style: italic;
}

#new-service-item-container > button {
  flex-grow: 1;
  padding: 0.75em;
  margin: 0.3em;
  width: 200px;
}

.color-gold {
  color: gold;
}

.technician-own-scheduling {
  background-color: yellow !important;
  border-color: yellow !important;
  color: black !important;
}

.technician-red {
  background-color: rgba(255, 0, 0, 0.4) !important;
  border-color: red !important;
  color: black;
}

.technician-orange {
  background-color: rgba(255, 165, 0, 0.4) !important;
  border-color: orange !important;
  color: black;
}

.technician-blue {
  background-color: rgba(0, 0, 255, 0.4) !important;
  border-color: blue !important;
  color: black;
}

#fault_table td.fault {
  opacity: 0.7 !important;
}

.fault-sm {
  padding: 0;
}

.fault-color-red {
  background-color: red;
  color: white;
}

.fault-color-yellow {
  background-color: gold;
  color: black;
}

.fault-color-black {
  background-color: black;
  color: white;
}

.fault-color-green {
  background-color: green;
  color: white;
}

.fault-color-no-color {
  /*background-color: white;*/
  background-color: transparent;
}

#fault_table.mt-0-important { // Fixed alignment problem caused by dataTable
  margin-top: 0 !important;
}

.selected-row {
  background-color: khaki !important;
}

.facebook-blue {
  background-color: #3b5998 !important;
  border-color: #3b5998 !important;
}

.ui-widget-overlay {
  opacity: .80 !important; /* Make sure to change both of these, as IE only sees the second one */
  filter: Alpha(Opacity=80) !important;

  background: rgb(50, 50, 50) !important; /* This will make it darker */
}

@media screen and (max-width: 600px) {
  button#chart-reset-zoom {
    margin: 0 auto !important;
    margin-left: 43px;
  }
}

button#chart-reset-zoom {
  margin-top: -30px;
  margin-left: 43px;
}

.item-completed {
  color: green;
}

.item-incomplete {
  color: orange;
}

.btn-success {
  color: white !important;
}

.btn-danger {
  color: white !important;
}

span#clock-feedback {
  margin-top: 10px;
  text-align: center;
}

.modal-address {
  display: block;
  width: auto;
}

span.last_serviced_modal {
  position: relative;
  top: -10px;
}

#file-gallery {
  min-height: 200px;
}

.file-thumbnail-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 200px;
  height: auto;
  padding: 1em;
  color: white;
  text-align: center;
}

.file-thumbnail {
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-title > a {
  color: white !important;
}

.pdf-placeholder-image {
  width: 100px;
  height: auto;
}

.fileinput-button {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.hover-hand:hover {
  cursor: pointer;
}

.fileinput-button input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  -ms-filter: 'alpha(opacity=0)';
  font-size: 200px !important;
  direction: ltr;
  cursor: pointer;
}

/* Fixes for IE < 8 */
@media screen {
  .fileinput-button input {
    filter: alpha(opacity=0);
    font-size: 100%;
    height: 100%;
  }
}

/*
.timer {
  position: relative;
}
@media screen and (min-width: 800px){
  .timer {
    position: fixed;
  }
}
*/
/* Rating Star Widgets Style */
.rating-stars ul {
  list-style-type: none;
  padding: 0;
  margin: 0;

  -moz-user-select: none;
  -webkit-user-select: none;
}

.rating-stars ul > li.star {
  display: inline-block;

}

/* Idle State of the stars */
.rating-stars ul > li.star > i.fa {
  font-size: 2.5em; /* Change the size of the stars */
  color: #ccc; /* Color on idle state */
}

/* Hover state of the stars */
.rating-stars ul > li.star.hover > i.fa {
  color: #FFCC36;
}

/* Selected state of the stars */
.rating-stars ul > li.star.selected > i.fa {
  color: #FF912C;
}
